import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one' // was five
import Footer from '../containers/layout/footer/footer-one'
import Hero from "../containers/index-infotechno/hero";
import AboutArea from "../containers/index-cybersecurity/about-area";
import TabsArea from "../containers/index-appointment/tabs-area-two";
import TestimonialArea from "../containers/global/testimonial-area/section-one";

import CTAArea from "../containers/global/cta-area/section-one";
import ClientsArea from "../containers/global/clients-area";
import PageHeader from "../containers/about-us/page-header";
import Feature from '../containers/landing/feature'
import Demos from '../containers/landing/demo-area'
import InnerDemos from '../containers/landing/inner-demo-area'
import TeamsArea from "../containers/index-appointment/team-area";
import CaseStudy from '../containers/landing/case-study'
import HeaderLayout from '../containers/landing/header-layout'
import BlogLayout from '../containers/landing/blog-layout'
import Plugins from '../containers/landing/plugins'
import ExtraFeature from '../containers/landing/extra-features'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Header />
    <main className="site-wrapper-reveal">
      <PageHeader />
      {/* <Hero /> */}
      <AboutArea />
      <TabsArea />
      {/* <TeamsArea /> */}
      <TestimonialArea />

      <CTAArea email="info@evolveadvisors.co.za" />
      <ClientsArea />
    </main>
    <Footer />
  </Layout>
  // <Layout location={location}>
  //   <SEO/>
  //   <Header transparent/>
  //   <main className="site-wrapper-reveal">
  //     <Hero/>
  //     <Feature/>
  //     <Demos/>
  //     <InnerDemos/>
  //     <CaseStudy/>
  //     <HeaderLayout/>
  //     <BlogLayout/>
  //     <Plugins/>
  //     <ExtraFeature/>
  //   </main>
  //   <Footer/>
  // </Layout>
);

export default IndexPage

import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../components/ui/tabs/layout-one'
import OurMission from './our-mission'
import { TabWrapper } from './tabs-area.style'

const TabsSection = ({ sectionStyles, sectionTitleStyle }) => {

    const tabsTwoSecQueryData = useStaticQuery(graphql`
      query TabsTwoSecQuery {
        indexAppointmentJson(id: { eq: "appointment-about-us" }) {
          id
          title
          subtitle
          tab_header {
            id
            title
          }
        }
      }
    `);

    const secdata = tabsTwoSecQueryData.indexAppointmentJson;
    const { tab_header } = secdata;

    return (
      <TabWrapper>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                {...sectionTitleStyle}
                subtitle={secdata.subtitle}
                title={secdata.title}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <OurMission />
            </Col>
          </Row>
        </Container>
      </TabWrapper>
    );
}

TabsSection.propTypes = {
    sectionTitleStyle: PropTypes.object
}

TabsSection.defaultProps = {
    sectionTitleStyle: {
        mb: '60px',
        responsive: {
            medium: {
                mb: '50px'
            }
        }
    }
}

export default TabsSection;
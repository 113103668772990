import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from "../../../components/ui/text";

import {PageHeaderWrap} from './page-header.style'

const PageHeader = ({
  about,
  textStyle, sectionStyle,
  titleStyle,
  descStyle,
}) => {
  const PageHeaderData = useStaticQuery(graphql`
    query AboutPageHeaderQuery {
      aboutUsJson(id: { eq: "about-page-header-data" }) {
        title
        desc
        bg_image {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 570, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  const { title, desc, bg_image } = PageHeaderData.aboutUsJson;
  const imageData = bg_image.childImageSharp.fluid;
  if (about) {
    return (
      <PageHeaderWrap fluid={imageData}>
        <Container>
          <Row textalign="center">
            <Col lg={8} ml="auto" mr="auto">
              {title && <Heading {...titleStyle}>{title}</Heading>}
              {desc && <Heading {...descStyle}>{desc}</Heading>}
            </Col>
          </Row>
        </Container>
      </PageHeaderWrap>
    );
  } else {
    return (
      <>
        <PageHeaderWrap fluid={imageData}>
          <Container>
            <Row textalign="center">
              <Col lg={8} ml="auto" mr="auto">
                <Heading {...titleStyle}>
                  Seeing the wood for the trees………
                </Heading>
              </Col>
            </Row>
          </Container>
        </PageHeaderWrap>
        <Container>
          <Row textalign="center">
            <Col lg={8} ml="auto" mr="auto">
              <Heading {...textStyle}>
                Having a business mentor, someone who can look in from a
                different perspective is valuable. <br /> Our <b>FRAMEWORK</b>{" "}
                and <b>APPROACH</b> delivers that value
              </Heading>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

PageHeader.propTypes = {
    descStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

PageHeader.defaultProps = {
  titleStyle: {
    as: "h1",
    color: "#fff",
    mb: "15px",
  },
  descStyle: {
    as: "h5",
    color: "#fff",
    fontweight: 400,
  },
  textStyle: {
    fontSize: "18px",
      mt: "20px",
    color: "#696969",
    mb: "20px",
    as: "h5",
    fontweight: 400,
  },
};

export default PageHeader;